import React from "react"
import { Link } from "gatsby"

import Home from './home';


const IndexPage = (props) => {
  return (
    <Home {...props}/>
  )
}

export default IndexPage
